import { createGlobalStyle } from 'styled-components';

import font from '../assets/fonts/Montserrat-Regular.woff2';

const Typography = createGlobalStyle`
  @font-face {
    font-family: Montserrat
    src: url(${font}) format('woff2');
  }
  * {
    // TODO: Fix font backups/pairings
    font-family: Montserrat, Roboto;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  a {
    color: var(--black);
    text-decoration-color: var(--orange);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
