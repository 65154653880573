import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const UlStyles = styled.ul`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 60vw;

  a {
    font-size: 2rem;
    text-decoration: none;
    &:hover {
      color: var(--orange);
    }
    &:active {
      color: var(--orange);
    }
  }

  @media (max-width: 768px) {
    background-color: #f8f9f9;
    flex-flow: column nowrap;
    height: 100vh;
    justify-content: flex-start;
    left: 0;
    overflow-y: auto;
    padding-top: 6rem;
    position: fixed;
    top: 0;
    transform: ${({ visible }) =>
      visible ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    width: 100vw;
    -webkit-overflow-scrolling: touch;
    a {
      color: var(--orange);
      font-size: 3rem;
    }
    li {
      padding: 3rem 0;
    }
  }
`;

export default function RightNav({ visible, setVisible }) {
  return (
    <UlStyles visible={visible}>
      <li>
        <Link
          to="/"
          activeStyle={{ color: 'var(--orange)' }}
          onClick={() => setVisible(!visible)}
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          to="/services"
          activeStyle={{ color: 'var(--orange)' }}
          onClick={() => setVisible(!visible)}
        >
          Services
        </Link>
      </li>
      <li>
        <Link
          to="/testimonials"
          activeStyle={{ color: 'var(--orange)' }}
          onClick={() => setVisible(!visible)}
        >
          Testimonials
        </Link>
      </li>
      <li>
        <Link
          to="/contact"
          activeStyle={{ color: 'var(--orange)' }}
          onClick={() => setVisible(!visible)}
        >
          Let's Meet
        </Link>
      </li>
    </UlStyles>
  );
}
