import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import logo from '../assets/images/paw_logo.png';

const LogoStyles = styled.div`
  /* padding: 15px; */
`;
// TODO Add actual Logo
export default function Logo() {
  return (
    <LogoStyles>
      <Link to="/">
        <StaticImage
          src="../assets/images/word-logo.png"
          alt="Critters in a house"
          placeholder="Critter Sitter"
          width={200}
          height={55}
        />
      </Link>
    </LogoStyles>
  );
}
