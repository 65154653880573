import React from 'react';
import styled from 'styled-components';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';

const FooterStyles = styled.footer`
  align-items: center;
  border-top: solid 1px black;
  display: flex;
  flex-flow: column nowrap;
  font-size: 1rem;
  justify-content: center;
  margin: 2rem 5rem;
  text-align: center;
  .insta {
    fill: red;
    font-size: 2rem;
  }
  .fb {
    fill: blue;
    font-size: 2rem;
  }
  @media (min-width: 800px) {
    flex-flow: row nowrap;
    font-size: 1.3rem;
    justify-content: space-around;
    text-align: left;
    .insta {
      font-size: 3rem;
    }
    .fb {
      font-size: 3rem;
    }
  }

  @media (min-width: 1000px) {
    font-size: 1.5rem;
    .insta {
      font-size: 3.5rem;
    }
    .fb {
      font-size: 3.5rem;
    }
  }

  @media (min-width: 1200px) {
    font-size: 1.8rem;
    .insta {
      font-size: 4rem;
    }
    .fb {
      font-size: 4rem;
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <div>
        <p>Pet Sitters International Member | Licensed, Bonded and Insured</p>
        <p>Covid: Masks worn, social distancing enforced</p>
        <p>Payment: Cash, Check or Credit</p>
        <p>Sí, hablo español</p>
      </div>
      <a
        href="https://www.instagram.com/humboldtcrittersitter/"
        target="_blank"
        rel="norefferer"
      >
        <FaInstagram className="insta" />
      </a>
      <a
        href="https://www.facebook.com/Humboldtcrittersitter"
        target="_blank"
        rel="norefferer"
      >
        <FaFacebookSquare className="fb" />
      </a>
      <p>&copy; Humboldt Critter Sitter {new Date().getFullYear()}</p>
    </FooterStyles>
  );
}
