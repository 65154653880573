import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const BurgerStyles = styled.div`
  display: none;
  height: 2rem;
  position: fixed;
  right: 20px;
  top: 15px;
  width: 2rem;
  z-index: 20;

  @media (max-width: 768px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
  }

  div {
    background-color: ${({ visible }) =>
      visible ? '#A9A9A9' : 'var(--black)'};
    border-radius: 10px;
    height: 0.25rem;
    transform-origin: 1px;
    transition: all 0.3s linear;
    width: 2rem;

    &:nth-child(1) {
      transform: ${({ visible }) => (visible ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ visible }) =>
        visible ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ visible }) => (visible ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ visible }) => (visible ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export default function Burger() {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <BurgerStyles visible={visible} onClick={() => setVisible(!visible)}>
        <div></div>
        <div></div>
        <div></div>
      </BurgerStyles>
      <RightNav visible={visible} setVisible={setVisible} />
    </>
  );
}
